.edit-product-modal {
    min-height: 250px;
}


.loading-box-modal {
    height: 1000px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-modal-tabs {
    height: 2.5rem;
}
#product-modal-tabs-tab-details,#product-modal-tabs-tab-variants,#product-modal-tabs-tab-images {
    height: 100%;
    color: black !important;
    text-align: center !important;
    background-color: transparent;
    border: none;
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: .5rem;
}
#product-modal-tabs-tab-details.nav-link.active,#product-modal-tabs-tab-variants.nav-link.active {
    border-bottom: 2px solid var(--primaryColor);
    color: var(--primaryColor) !important;
}
.edit-product-container {
    justify-content: center;
    margin: auto;
    max-width: 90vw !important;
}
@media screen and (max-width: 768px) {
    .edit-product-container {
        width: 90vw !important;
    }
}

@media screen and (min-width: 768px) {
    .edit-product-container {
        width: 75vw !important;
    }
}

@media screen and (min-width: 1200px) {
    .edit-product-container {
        width: 60vw !important;
    }
}

@media screen and (min-width: 1400px) {
    .edit-product-container {
        width: 55vw !important;
    }
}


