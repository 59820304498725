.variant-option-pill div, .pill-input {
    flex: 7;
}
.pill-input {
    cursor: pointer;
    font-size: 0.85rem;
    border-bottom: none !important;
}
.variant-option-pill.selected {
    transition: 0.1s;
    background-color: var(--secondaryColor) !important;
    color: white;
}
.variant-option-pill.disabled {
    opacity: 0.5;    
}

.pill-input:disabled {
    background-color: transparent
}
.pill-input,
.pill-input:active,
.pill-input:focus {
    background-color: transparent;
}

.pill-button {
    background-color: transparent;
    border: none;
    flex: 1;
}

.pill-button svg {
    font-size: 0.9rem;
    opacity: 0.7;
}

.variant-option-pill {
    margin-left: 0.15rem;
    width: auto;
    max-width: 200px;
    text-overflow: ellipsis;
    text-wrap: wrap;
    transition: 0.1s;
    background-color: rgba(63, 62, 62, 0.14) !important;
    color: rgba(0, 0, 0, 0.73);
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    height: 2.2rem;
}
.variant-option-pill.new {
    max-width: 250px;
}

.variant-option-pill:hover,
.variant-option-pill:hover {
    transition: .1s;
    background-color: rgba(63, 62, 62, 0.207) !important;

}