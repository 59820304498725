#admin-orders-container {
  .table-element-address {
    text-overflow: ellipsis;
    max-width: 10rem;
  }

  .cancel-order-modal p {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
  }

  .btn-danger {
    padding: 0.3rem !important;
  }
  
}
.tabs-container {
  width: 80%;
  overflow-x:scroll;
}

.export-button {
  /* Style the export button */
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

  
  /* Status Tags */
  .status-tag {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
  }
  
  .status-processing {
    color: #fff;
    background-color: #17a2b8;
  }
  
  .status-dispatched {
    color: #fff;
    background-color: #28a745;
  }
  
  .status-cancelled {
    color: #fff;
    background-color: #dc3545;
  }
  
  .header-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding:2px;
    margin: 0;
    border-bottom: 1px solid #ccc;
  }
  
  .action-dropdown {
    /* Style the dropdown container */
    position: relative;
  }
  
  .action-dropdown select {
    /* Style the select element */
    background-color: #f0f0f0; /* Example background color */
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .apply-button {
    /* Style the Apply button */
    background-color: #007bff; /* Example color */
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-bar {
    /* Style the search bar container */
    display: flex;
    align-items: center;
  }
  
  .search-bar input {
    /* Style the input field */
    background-color: #f0f0f0; /* Example background color */
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .search-button {
    /* Style the search button */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  .selecting-buttons {
    margin-right: 0.75rem !important ; /* Adjust the value as needed */
    margin-left: 0.5rem;  /* Optional, adjust as needed */
    margin-bottom: 0;
  }


  /* Action Bar Container */
.action-bar {
  background-color: #f8f9fa; /* Light background color */
  padding: 15px;
  border-radius: 5px; /* Slightly rounded corners */
  justify-content: end !important;

  /* margin-bottom: 20px;  */
}

/* Action Dropdown */


/* Apply Button */
.action-bar .btn-primary {
  background-color: var(--secondaryColor) !important;
  border-color: var(--secondaryColor) !important;
  padding: 8px 8px; /* Adjust padding to match dropdown */
  border-radius: 4px;
  font-size: 14px;
}

.action-bar .button-cancel {
  background-color: rgb(191, 67, 67) !important;
  color: white;
  margin: 0;
  margin-left: auto !important;


}

.action-bar .button-cancel:hover {
  background-color: rgb(130, 26, 26) !important;
  color: white;
  margin: 0;

}

/* Search Bar Container */
.action-bar .search-bar {
  display: flex;
  align-items: center;
}

/* Search Input */
.action-bar input[type="text"] {
  border: 1px solid #ced4da;
  padding: 8px 12px;
  border-radius: 4px 0 0 4px; /* Rounded left corners */
  margin-right: -1px; /* Remove space between input and button */
  width: 250px; /* Adjust width as needed */
  font-size: 14px;
}

/* Search Button */
.action-bar .search-button {
  /* Style your search button here (background, icon, etc.) */
  margin-left: 3px;
  border: 1px solid #ced4da;
  border-radius: 0 4px 4px 0; /* Rounded right corners */
  background-color: var(--secondaryColor) !important; /* Match search input background */
}

.action-bar .form-select {
  min-width: 200px; /* Set a minimum width as needed */
  max-width: 100%;  /* Allow it to expand based on content */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;    /* Hide overflow if content is too long */
  text-overflow: ellipsis; /* Show ellipsis if content is too long */
  font-size: 14px;

}