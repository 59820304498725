.productScreenContainer {
  padding: 3rem;
}

.tag.label.label-info {
  background: var(--primaryColor);
}

.number-product-screen {
  text-align: center;
}
.product-description-container img {
  max-width: 100%;
}


.quantity-message-container {
  color: #e0103a;
  min-height: 20px;
  font-size: 0.85rem
}

.option-value-list {
  margin-bottom: 0.4rem;
}
.option-value-list h4 {
  font-size: 0.9rem;
}
.value-list {
  display: flex;
}
/* @media screen and (max-width: 1200px) {
  .product-stock-info {
    margin: 0;
  }
} */