.dynamic-list-table {
    table-layout: fixed;
    .actions-col, .checkbox-col {
            width: 1.8rem !important;
            vertical-align: middle;
            overflow: visible;
    }
    &:has(.flap-status-cell) {
        .actions-col{
            width: auto !important;
        }
        
    }
    .checkbox-col .checkbox-container {
            .checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
    }
           .actions-col .actions-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

        }
    thead {
        font-size: 0.875rem;
        vertical-align: middle;
        text-align: center;
        th {
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
            /* word-break: break-all; */
        }
    }
    tbody {
        font-size: 0.85rem;
        border-top: none !important;
        td {
            background-color:  white;
            word-break:normal;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
        .dropdown {
            .dropdown-toggle {
                cursor: pointer;
                transition: 0.1s;
                font-size: 1rem;
                transform: rotate(180deg);
            }
    
            .dropdown-toggle.show {
                transition: 0.1s;
                color: rgba(0, 0, 0, 0.525);
                transform: rotate(0);
            }
    
            &:has(+.details-menu.show) {
                background-color: red;
            }
    
            &::before {
                display: none;
            }
        }
        .details-menu {
            right: .5rem;
            background-color: white !important;
            border-width: 2px;
            font-size: .8rem;
            padding: 0;
            .action {
                a {
                    transition: 0.3s;
                    font-weight: 400;
                    text-align: right;
                    padding: 0 0.2rem;

                }
                &:hover {
                    transition: 0.3s;
                    background-color: #eff2f5a2;
                }
            }
        }
}