/* General Status Dropdown Styles */
.status-dropdown {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: normal;
  min-width: 100px;
  text-align: center;
  margin: 1.5px;
}

td > .status-dropdown {
  display: block;
  margin: 0 ;
}

.status-dropdown .dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  /* padding: 6px 12px; */
  border-radius: 4px;
  font-weight: normal;
  min-width: 100px;
  position: relative;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

.status-dropdown .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.status-dropdown .dropdown-menu {
  width: 100%;
  background-color: #acacac;
  border: 1px solid #525151;
  border-radius: 4px;
  padding: 2px;
  /* padding: 2px;
  padding-right: 10px; */
  box-shadow: none;
  text-align: right;
}

/* Style for each dropdown item */
.status-dropdown .dropdown-item {
  /* padding: 10px 20px; */
  color: #525151;
  transition: background-color 0.3s, color 0.3s, border-radius 15px;
  text-align: right;
  z-index: 1050 !important;
}

/* Hover and active styles for dropdown items */
.status-dropdown .dropdown-item:hover,
.status-dropdown .dropdown-item:focus,
.status-dropdown .dropdown-item.active {
  background-color:var(--background-color);
  color:  #525151;
  border-radius: 4px;
}

/* Last item should not have a bottom border */
.status-dropdown .dropdown-item:last-child {
  border-bottom: none;
}
.status-button {
    font-size: 0.85rem;
    font-weight: 500 !important;
}
/* Status-specific styles */
.status-dropdown-cancelled .dropdown-toggle {
  background-color: #F8D7DA !important;
  color: #721C24;
  border: 1px solid #F5C6CB;
}

.status-dropdown-pending-payment .dropdown-toggle {
  background-color: #FFE0B2;
  color: #856404;
  border: 1px solid #FFE599;
}

.status-dropdown-payment-confirmation .dropdown-toggle {
  background-color: #FFE0B2;
  color: #856404;
  border: 1px solid #FFE599;
}

.status-dropdown-awaiting-confirmation .dropdown-toggle {
  background-color: #FFF3CD;
  color: #856404;
  border: 1px solid #FFEEBA;
}

.status-dropdown-completed .dropdown-toggle {
  background-color: #D4EDDA;
  color: #155724;
  border: 1px solid #C3E6CB;
}

/* Add other status-specific styles as needed */
