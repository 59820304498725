body {
  overscroll-behavior: none;
  --mp-blue: #009ee3;
}

main {
  flex: 1 1;
  margin-top: 80px;
  padding: 1rem 1rem;
  box-sizing: border-box;
  padding-bottom: 0 !important;
  background-color: var(--background-color);
  margin-bottom: 0 !important;
  overscroll-behavior: none;
  width: 100vw;
}
main:has(.admin-con) {
  background-color: var(--background-color);
  height: calc(100% - 50px);
  margin: 0;
  padding: 0;
}
/* Width Classes */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}
/* CLASS USED FOR CONTAINER ALIGNED FOR ADMIN SCREEN */
.admin-con {
  height: 100%;
  margin: 0;
  width: 100%;
  margin-top: 50px;
}
@media screen and (min-width: 992px){
  .admin-con {
    width: calc(100% - 13rem) !important;
    margin-left: auto;
  }
}

/* HEADER && NAVBAR */
header {
  display: flex;
  background-color: var(--primaryColor);
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  z-index: 1000;
}
.card .dynamic-table {
  border-left-color: transparent ;
  border-right-color:  transparent;
}

header img {
  height: 90px;
}

.navbar {
  width: 100vw;
  justify-content: center;
  padding-left: 2rem;
  height: 90px;
  align-items: flex-end;
  flex-direction: row;
}

.navbar ul {
  padding: 0.2rem 0.9rem;
}

.navbar li {
  margin-right: 0.25rem;
}




.nav {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  align-content: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  align-items: unset;
  width: fit-content;
}

.nav-link {
  display: -webkit-inline-box;
  align-items: center;
  padding: 4px 8px;
  color: var(--navFontColor) !important;
  height: 32px;
  padding-left: 0px;
  padding-right: 0.5rem;
  font-weight: 700;
  width: fit-content;
}



.nav-link:focus,
.nav-link:hover,
.btn-primary-search:hover i {
  color: var(--background-color) !important;
  filter: brightness(93%);
  transition: .09s;
}

.dropdown-menu {
  background-color: var(--secondaryColor);
  text-align: center;
}

.dropdown-item {
  width: 100%;
  text-align: left;
  padding: 0.3rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  padding: 0.5rem 0.5rem;
}

.nav-item.dropdown {
  height: 32px !important;
  text-align: center !important;
}
header .badge {
  padding: 0.25em;
  margin-left: -6px;
}
/* END HEADER && NAVBAR */
/* ESTILOS GENERALES*/
.img-large {
  max-width: 100%;
}
.prod-page-img-lg {
  max-width: 400px;
  max-height: 400px;
  aspect-ratio: 1/1;
}
.img-thumbnail {
  max-height: 80px;
}
.small-container {
  max-width: 600px;
}
i {
  color: var(--IconsColor);
}

.col-12 {
  padding: 0px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--secondaryColor) !important;
  color: var(--btnFontColor) !important;
  border: none !important;
  margin: 0 auto;
  font-weight: 500;
  outline: 0;
}

.btn-primary:hover {
  /* text-shadow: 2px 2px 4px #000000;
  box-shadow: 1px 1px 2px #000000; */
  filter: brightness(95%);
}

.eyeButton {
  text-shadow: 2px 2px 4px #000000;
  border-left: none;
  background: white;
  border-bottom: 1px solid var(--secondaryColor);
  padding: 0.5px 1rem;
  height: 32px;
  outline: 0 !important;
}

.eyeButton:focus {
  box-shadow: none !important;
}

.btn-outline-primary:hover {
  background-color: var(--primaryColor);
  color: #fff;
  outline: none;
}
.btn-outline-primary:hover, .btn-outline-primary:focus,
.btn-outline-primary:active {
  outline: none;
  box-shadow: none;
}
.envios-img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.mobile {
  display: contents;
}


.navbar-brand {
  max-height: 100%;
  justify-content: flex-start;
  margin: -0.7rem;
}

li {
  margin: 0;
  padding: 4px 0px;
  width: unset;
}

.rightlinks {
  width: 20%;
  justify-content: flex-end;
}

main {
  margin-bottom: 150px;
}

.navbar-expand-lg .navbar-nav {
  margin: 0 1rem;
}
.input-group {
  flex-wrap: nowrap;
  justify-content: flex-end;
  height: 32px;
  padding: 4px 8px;
}
.form-control {
  height: 32px;
  align-items: center;
  border: none;
  border-bottom: 1px solid var(--secondaryColor) !important;
  border-radius: 0%;
}

.search-input {
  width: 10rem;
}

input:focus,
select:focus,
select,
input.form-control:focus {
  outline: none !important;

  outline-width: 0 !important;

  box-shadow: none;

  -moz-box-shadow: none;

  -webkit-box-shadow: none;
}

.listsAlert .alert {
  width: max-content;
}
@media screen and (min-width: 992px) {
  .prod-cat-col {
    width: 20% !important;
  }

  .nav-link .d-lg-block {
    padding-left: 2.5px !important;
  }
}

@media screen and (max-width: 991px) {

  .navbar {
    flex-wrap: nowrap;
    height: 80px;
    width: 100vw;
    align-items: center;
  }

  .navbar-collapse-Admin {
    justify-content: unset !important;
    width: 0 !important;
  }




  .mobile-menu-admin li {
    margin-left: 1rem;
  }

  .navbar-collapse {
    position: fixed;
    top: 80px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    background-color: var(--secondaryColor);
    /* box-shadow: inset -20px 0px 20px 10px #00377a45; */
  }

  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

  .rightlinks {
    width: 32px;
    justify-content: flex-end;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }

  .nav-link {
    display: inline-flex;
  }

  .rounded-pill {
    transform: scale(0.8);
    margin-left: -0.5rem;
    display: inline-flex;
  }

  .navbar-brand {
    justify-content: flex-start;
    margin: 0 auto;
    margin-left: 2rem;
  }

  .dropdown-menu {
    background-color: var(--secondaryColor);
    text-align: center;
  }

  .dropdown-item {
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
  }

  .input-group {
    padding: 4px 4px !important;
  }

  .nav-item.dropdown {
    height: 32px !important;
    text-align: right !important;
  }

  .whatsapp-icon {
    margin-top: 10px;
  }
  .banner-preview {
    max-width: inherit !important;
    max-height: unset !important;
  }
}

.centered {
  justify-content: center;
  align-items: center;
}

.anularCero {
  color: var(--background-color);
}

.font-weight-700 {
  font-weight: 700;
}

.nav-link:focus i,
.nav-link:hover i,
.nav-link:hover svg,
.nav-link:focus svg {
  color: var(--background-color) !important;
  filter: brightness(93%);
  transition: color 0.15s;
}
.nav-link:hover i.fa.fa-shopping-cart  ~ .badge,
.nav-link:focus i.fa.fa-shopping-cart ~ .badge {
  filter: brightness(93%);
}

.empty-list-image-preview {
  max-width: 300px;
  max-height: 300px;
}

.banner-preview {
  max-width: 700px;
  max-height: 300px;
}

.product-image-preview {
  padding: 0;
  width: 300px;
  height: 300px;
  margin: auto;
  border: 1px solid black;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
}

.font-weight-700 {
  font-weight: 700;
}

.banner-preview {
  max-width: 700px;
  max-height: 300px;
}

.product-image-preview {
  padding: 0;
  width: 300px;
  height: 300px;
  margin: auto;
  border: 1px solid black;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
}

.container-banner-preview {
  padding: 0;
  margin: auto;
  border: 1px solid black;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
}
.row:has(.container-banner-preview) {
  margin: 2.5% auto 7.5%;
}
.row:has(.product-image-preview) {
  margin: 2.5% auto 2.5%;
}

/* CARD VARIANTS */
.alert-alert-primary {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

/* CART */

/* Small devices (tablets, 768px and up) */




.p-price,
.cart-row span {
  color: rgb(20, 80, 150);
  margin: auto !important;
}
