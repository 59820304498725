/* General Status Button Styles */
.status-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: normal;
  min-width: 100px;
  text-align: center;
  margin: 1.5px;
}

td > .status-button {
  display: block;
  margin: 0;
}

.status-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  border-radius: 4px;
  font-weight: normal;
  min-width: 100px;
  position: relative;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  text-decoration: none;
}


/* Status-specific styles */
.status-button-cancelled {
  background-color: #F8D7DA !important;
  color: #721C24;
  border: 1px solid #F5C6CB;
}

.status-button-pending-payment {
  background-color: #FFE0B2;
  color: #856404;
  border: 1px solid #FFE599;
}
.status-button-pending-payment:hover {
  background-color: #eac894 ;
  color: #856404;
  border: 1px solid #856404;
}
.status-button-payment-confirmation {
  background-color: #ffd5b2;
  color: #813c10;
  border: 1px solid #ffbc99;
}
.status-button-payment-confirmation:hover {
  background-color: #e79c2c ;
  color: #856404;
  border: 1px solid #856404;
}

.status-button-awaiting-confirmation {
  background-color: #FFF3CD;
  color: #856404;
  border: 1px solid #FFEEBA;
}

.status-button-awaiting-confirmation:hover {
  background-color: #decf9f ;
  color: #856404;
  border: 1px solid #856404;
}

.status-button-completed {
  background-color: #D4EDDA;
  color: #155724;
  border: 1px solid #C3E6CB;
}

/* styles.css */
.button-confirm {
  background-color: var(--secondaryColor) !important; 
  color: white;
  margin: 0;

}
.button-confirm:hover {
  background-color: #4c6210 !important; 
  color: white;
  margin: 0;
}

.button-cancel {
  background-color: rgb(191, 67, 67) !important;
  color: white;
  margin: 0;

}

.button-cancel:hover {
  background-color: rgb(130, 26, 26) !important;
  color: white;
  margin: 0;

}

.button-delete {
  background-color: red !important;
  color: white;
  margin: 0;

}

.button-default {
  background-color: lightgrey;
  color: black;
  margin: 0;

}

.button-confirm-payment {
  background-color: rgb(215, 191, 70) !important;
  color: black;
  margin: 0;

}

.button-confirm-payment:hover{
  background-color: rgb(148, 128, 28) !important;
  color: black;
  margin: 0;

}

/* Hover and active styles for dropdown items */
/* .status-button:hover {
  background-color:var(--background-color);
  color:  #525151;
  border-radius: 4px;
} */

/* Add other status-specific styles as needed */
