#faq-config {
  width: 100%;
  margin: 0;
}
#lista-preguntas {
  font-size: 1rem;
  border-radius: 0;
}

#lista-preguntas .list-group-item {
  border-right: none;
  border-left: none;
  padding: 0.2rem 0;
}
#label-boton-faq {
  width: 40%;
}

#pen-checkbox-faq {
  font-size: 0.75rem;
  width: 5px;
}

.action-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.14);
  transition: 0.2s;
}
.action-button :hover {
  color: black;
  transition: 0.2s;
}
#label-boton {
  width: 48%;
}
#label-activo {
  font-weight: 400;
  font-size: 0.75rem;
}
